import React from 'react';
import { Link } from 'react-router-dom';
import environment from '../environment';

function Aim(){
    return (
    <>
    <div className="site-section">
      <div className="container">


        <div className="row mb-5 justify-content-center text-center">
          <div className="col-lg-6 mb-5">
            <h2 className="section-title-underline mb-3">
              <span>Courses Offered</span>
            </h2>
          </div>
        </div>

        <div className="row">
            <div className="col-sm-6">
                <div className="course-1-item">
                  <div className="thumnail">
                    <div className="category"><h3>General Nursing and Midwifery</h3></div>  
                  </div>
                  <div className="course-1-content pb-4">
                    <h2>Duration: 3 Years</h2>
                    <p className="desc mb-4">General Nursing and Midwifery or GNM is a three-year diploma course designed for aspirants
                     who want to pursue a career in clinical nursing</p>
                    <p><Link to={environment.homePage+'/application'} className="btn btn-primary rounded-0 px-4">Enroll In This Course</Link></p>
                  </div>
                </div>
            </div>
            <div className="col-sm-6">
                <div className="course-1-item">
                  <div className="thumnail">
                    <div className="category"><h3>B.Sc(Nursing) </h3></div>  
                  </div>
                  <div className="course-1-content pb-4">
                    <h2>Duration: 4 Years</h2>
                    <p className="desc mb-4">The course is offered as a four years undergraduate programme for all
                     students who have cleared class 12th from the stream of science.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    <p><Link to={environment.homePage+'/application'} className="btn btn-primary rounded-0 px-4">Enroll In This Course</Link></p>
                  </div>
                </div>
            </div>
        </div>

        
        
      </div>
    </div>

    


    <div className="section-bg style-1" >
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <h2 className="section-title-underline style-2">
              <span>About Our College</span>
            </h2>
          </div>
          <div className="col-lg-8" >
              <p style={{color:"#fff"}}>


Principal Desk: It is a matter of pride to be nurse in the present millennium the nurses are faced with the challenges of advancing technology, informatics system, modern demands and development. We the nursing professionals expect our students to equip themselves with update knowledge, skills and the right attitude to meet professionals demands of our society.
</p><p style={{color:"#fff"}}>
As the principal of Priyadarshini college of nursing, it is my pleasure to welcome you to our college. The college has rich history of education exceptional nurses who are making a difference in the health care of individuals in our region and beyond.

              </p>
          </div>
        </div>
      </div>
    </div>
    <div className="site-section">
    <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="align-items-center">
              <h2 className="section-title-underline style-1">
                <span>Our Aim</span>
              </h2>
              <p>Our is aim is to prepare graduate nurses to assume responsibilities as professionals, competent nurses and midwives in order to meet the growing heath needs of people globally. </p>
            </div>
          </div>
        </div>
    </div>
    </div>
    </>
    )
}

export default Aim;