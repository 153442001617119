import  React  from 'react';

const NoMatch = ({ location }) => (
    <section className="slice slice-lg">
        <div className="container">
            <div className='ui inverted red raised very padded text container segment'>
              <strong>Error!</strong> No route found matching:
              <div className='ui inverted black segment'>
                <code>{location.pathname}</code>
              </div>
            </div>
        </div>
      </section>
);

export default NoMatch;