import React from "react";
import Field from "../components/Field";
import { appForm, validateForm } from "../components/formFields";

import GuestService from "../services/GuestService";

class Application extends React.Component {
  constructor(props) {
    super(props);
    var state = Object.assign(
      {},
      { fields: {}, fieldErrors: {}, status: "", buttonTitle: "","date_of_birth":'' },
    );
    this.appForm = appForm;
    Object.keys(this.appForm).forEach(function (key) {
      state.fields[key] = "";
    });
    this.state = state;
  }


  resetForm = () => {
    var stateObject = Object.assign({}, this.state);
    Object.keys(this.appForm).forEach(function (key) {
      stateObject.fields[key] = "";
    });
    this.setState({ fields: stateObject.fields,"date_of_birth":'' });
  };

  getData = () => {
    return {
      data: {
        type: "node--nursing_course_application_form",
        attributes: {
          title: this.state.fields["name"],
          field_nursing_app_email: this.state.fields["email"],
          field_nursing_app_phone: this.state.fields["mobile"],
          field_nursing_app_course: this.state.fields["course"],
          field_nursing_dob: this.state.date_of_birth,
          field_nursing_qualification: this.state.fields["qualification"],
        }
      },
    };
  };

  onFormSubmit = (evt) => {
    evt.preventDefault();
    var result = validateForm(this.state.fields, this.appForm);
    if (result.error) {
      this.setState(
        Object.assign({}, this.state, { fieldErrors: result.fieldErrors })
      );
    } else {
      this.setState(
        { status: "Please Wait", buttonTitle: "Please Wait" },
        () => {
          GuestService.fetchInfo(this.getData(), "application")
            .then((response) => {
              var resObj = JSON.parse(response);
              if (resObj.data !== undefined) {
                this.resetForm();
                this.setState({
                  status:
                    "Thanks for submitting the details. We will contact you shortly.",
                  buttonTitle: "",
                });
              } else {
                let message = "Please Submit Again";
                if (resObj.errors[0]["title"])
                  message = resObj.errors[0]["title"];
                if (resObj.errors[0]["detail"])
                  message += ": " + resObj.errors[0]["detail"];

                this.setState({ status: message, buttonTitle: "" });
              }
            })
            .catch((err) => {
              this.setState({ status: err, buttonTitle: "" }, () => {});
            });
        }
      );
    }
  };

  onInputChange = ({ name, value, error }) => {
    const fields = Object.assign({}, this.state.fields);
    const fieldErrors = Object.assign({}, this.state.fieldErrors);

    if(name === 'dob'){
        this.setState({date_of_birth:value.split(":")[1]})
        value = value.split(":")[0]
    }

    fields[name] = value;
    fieldErrors[name] = error;
    
    

    this.setState({ fields, fieldErrors });
  };

  renderFields = () => {
    var jsx = [];
    var i = 0;
    Object.keys(this.appForm).forEach((key) => {
      jsx[i++] = (
        <Field
          labelName={this.appForm[key]["labelName"]}
          // isRequired={this.appForm[key]["isRequired"]}
          placeHolder={this.appForm[key]["placeholder"]}
          type={this.appForm[key]["type"]}
          name={key}
          key={key}
          validate={
            this.appForm[key]["validate"]
              ? this.appForm[key]["validate"]
              : undefined
          }
          onChange={this.onInputChange}
          value={this.state.fields[key]}
          error={this.state.fieldErrors[key] ? this.state.fieldErrors[key] : ""}
          selectValues={this.appForm[key]["selectValues"]}
        />
      );
    });
    return jsx;
  };

  render() {
    return (
      <div className="container" >
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-md-6 offset-md-3">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">Enroll for course</h1>
                      </div>
                      <form
                        id="login-user"
                        className="user"
                        onSubmit={this.onFormSubmit}
                      >
                        {this.renderFields()}
                        <div
                          onClick={
                            this.state.buttonTitle.trim() === ""
                              ? this.onFormSubmit
                              : () => {}
                          }
                          title={
                            this.state.buttonTitle.trim() === ""
                              ? "Register"
                              : this.state.buttonTitle
                          }
                          className="btn btn-primary btn-user btn-block"
                        >
                          Submit
                        </div>
                        <div id="register-form-status">{this.state.status}</div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Application;
