import  React,{useState,useEffect}  from 'react';

const About = (props) => {

    const [college,setCollege] = useState('');
    const [features,setFeatures] = useState('');
    const [aim,setAim] = useState('');
    const [dataLoaded,setDataLoaded] = useState(false);

    useEffect(()=>{
        let isCancelled = false;
        if(dataLoaded === false){
            if(props.about !== null){
                if(props.about.data && props.about["data"].length){
                    for (let index = 0; index < props.about["data"].length; index++) {
                        const element = props.about["data"][index];
                        if(element.id === "35d814fd-5130-4377-bff3-0b248332f3d7"){
                            if(isCancelled === false)
                            setCollege(
                                "<p>"+element.attributes.body.summary+"</p>"+element.attributes.body.processed
                            )
                        }

                        if(element.id === "77f90d60-1671-4ea8-84c2-b70dee8dd3dd"){
                            if(isCancelled === false)
                            setFeatures(
                                "<p>"+element.attributes.body.summary+"</p>"+element.attributes.body.processed
                            )
                        }

                        if(element.id === "8e897156-d5bd-41ad-9d1c-96b093ee51d4"){
                            if(isCancelled === false)
                            setAim(
                                "<p>"+element.attributes.body.summary+"</p>"+element.attributes.body.processed
                            )
                        }
                        
                    }
                }
                setDataLoaded(true)
            }

        }

        return function cleanup() {
            isCancelled = true;
        };

    },[dataLoaded,props.about])
    if(props.about === null){
        
    }
    else{
        
    }

    return (
        <div >
        <div className="site-section">
            <div className="container">

                <div className="row mb-5">
                    <div className="col-lg-12 text-center">
                        <h2 className="section-title-underline mb-5">
                            <span>About Us</span>
                        </h2>
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: college }}></p>
                </div>

                <div className="row mb-5">
                    <div className="col-lg-12 text-center">
                        <h2 className="section-title-underline mb-5">
                            <span>Features</span>
                        </h2>
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: features }}></p>
                </div>

                <div className="row mb-5">
                    <div className="col-lg-12 text-center">
                        <h2 className="section-title-underline mb-5">
                            <span>Aim,Vision,Mission </span>
                        </h2>
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: aim }}></p>
                </div>
            </div>
        </div>
        </div>
    )
};

export default About;