import  React,{useState,useEffect}  from 'react';

const Courses = (props) => {

    const [bsc,setBsc] = useState('');
    const [bsc_title,setBscTitle] = useState('');

    const [gnm,setGnm] = useState('');
    const [gnm_title,setGnmTitle] = useState('');

    const [dataLoaded,setDataLoaded] = useState(false);

    useEffect(()=>{
        let isCancelled = false;
        if(dataLoaded === false){
            if(props.about !== null){
                if(props.about.data && props.about["data"].length){
                    for (let index = 0; index < props.about["data"].length; index++) {
                        const element = props.about["data"][index];
                        if(element.id === "a9d5c077-c04f-4b07-84d4-d321e97c3ee4"){
                            if(isCancelled === false)
                            setBsc(
                                "<p>"+element.attributes.body.summary+"</p>"+element.attributes.body.processed
                            )
                            setBscTitle(element.attributes.title)
                        }

                        if(element.id === "16d6a2de-f3a5-4251-b1fa-9333d6ee1e4b"){
                            if(isCancelled === false)
                            setGnm(
                                "<p>"+element.attributes.body.summary+"</p>"+element.attributes.body.processed
                            )
                            setGnmTitle(element.attributes.title)
                        }
                        
                    }
                }
                setDataLoaded(true)
            }

        }

        return function cleanup() {
            isCancelled = true;
        };

    },[dataLoaded,props.about])
    if(props.about === null){
        
    }
    else{
        
    }

    return (
        <div >
        <div className="site-section">
            <div className="container">
                <div className="row mb-5 ">
                    <div className="col-md-12 mb-5">
                        <h2 className="section-title-underline text-center">
                            <span>{bsc_title}</span>
                        </h2>
                        <p dangerouslySetInnerHTML={{ __html: bsc }}></p>
                    </div>
                    
                </div>
                <div className="row mb-5 ">
                    <div className="col-md-12 mb-5">
                        <h2 className="section-title-underline text-center">
                            <span>{gnm_title}</span>
                        </h2>
                        <p dangerouslySetInnerHTML={{ __html: gnm }}></p>
                    </div>
                    
                </div>
            </div>
        </div>
        </div>
    )
};

export default Courses;