import validator from 'validator';

var validateEmail = function(value){
    if(validator.isEmail(value))
        return '';
    
    return 'Enter valid email';
};

var validateForm = function(formData,form){
    var result = {
        "fieldErrors": {},
        "error": false
    };
    Object.keys(formData).forEach((key)=>{
        if(form[key]['isRequired'])
        {
            if(validator.isEmpty(formData[key]))
            {
                result['fieldErrors'][key] = key + ' is required';
            }
        }
    });
    if(Object.keys(result['fieldErrors']).length > 0)
        result["error"] = true;

    return result;
};


var appForm = {
    "name": {
        "type":"text",
        "isRequired":true,
        "labelName": "Name",
        "placeholder": "Enter Name",
        "value": "",
        "selectValues": []
    },
    "dob": {
        "type":"date",
        "isRequired":true,
        "labelName": "DOB",
        "placeholder": "Date Of Birth",
        "value": "",
        "selectValues": []
    },
    "email": {
        "type":"email",
        "isRequired":true,
        "labelName": "Email",
        "placeholder": "Enter Email",
        "value": "",
        "validate":validateEmail,
        "selectValues": []
    },
    "mobile": {
        "type":"text",
        "isRequired":true,
        "labelName": "Mobile",
        "placeholder": "Enter Mobile",
        "value": "",
        "selectValues": []
    },
    "course": {
        "type":"select",
        "isRequired":true,
        "labelName": "Course",
        "placeholder": "Course",
        "value": "",
        "selectValues":[
            {
            "title":"--Select Course--",
            "value":""
            },
            {
                "title":"BSc(N) 4years",
                "value":"BSc(N) 4years"
            },
            {
                "title":"GNM 3years",
                "value":"GNM 3years"
            }
        ]
    },
    "qualification": {
        "type":"textarea",
        "isRequired":true,
        "labelName": "Qualification",
        "placeholder": "Qualification",
        "value": "",
        "selectValues": []
    },

};

var contactForm = {
    "name": {
        "type":"text",
        "isRequired":true,
        "labelName": "Name",
        "placeholder": "Enter Name",
        "value": "",
        "selectValues": []
    },
    "email": {
        "type":"email",
        "isRequired":true,
        "labelName": "Email",
        "placeholder": "Enter Email",
        "value": "",
        "validate":validateEmail,
        "selectValues": []
    },
    "mobile": {
        "type":"text",
        "isRequired":true,
        "labelName": "Mobile",
        "placeholder": "Enter Mobile",
        "value": "",
        "selectValues": []
    },
    "message": {
        "type":"textarea",
        "isRequired":true,
        "labelName": "Message",
        "placeholder": "Message",
        "value": "",
        "selectValues": []
    },

};

export { appForm,validateForm,contactForm };