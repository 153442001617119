import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";

const InputField = (props)=>{

    const handleDateChange = date => {
        console.log(date)
        props.onChange(moment(date).format('DD-MM-YYYY')+':'+moment(date).format('YYYY-MM-DD'),'date')
      };

    if(props.type === 'select')
    {
        return (
            <select 
            className="form-control form-control-user" 
            onChange={props.onChange} defaultValue={props.value}
            required={props.isRequired ? true: false} >
                {
                    props.selectValues.map(option=>{
                        return <option  key={option.value} value={option.value}>{option.title}</option>
                    })
                }

            </select>
        )
    }
    if(props.type==='date'){
        return <DatePicker className="form-control form-control-user" showYearDropdown placeholderText={props.placeHolder}
         value={props.value} onChange={handleDateChange} />
    }
    if(props.type==='textarea'){
        return (
            <textarea className="form-control form-control-user"
                value={props.value}
                placeholder={props.placeHolder}
                onChange={props.onChange}
                required={props.isRequired ? true: false}
            ></textarea>
        )
    }
    return (
        <input className="form-control form-control-user"
            type={props.type ? props.type: 'text'} 
            value={props.value}
            placeholder={props.placeHolder}
            onChange={props.onChange}
            required={props.isRequired ? true: false}
        />
    );
}

class Field extends React.Component{
    static propTypes = {
        placeHolder: PropTypes.string,
        name: PropTypes.string.isRequired,
        value: PropTypes.string,
        validate: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        type: PropTypes.string,
        labelName: PropTypes.string,
        isRequired: PropTypes.bool,
        error: PropTypes.string
    };

    
    constructor()
    {
        super();
        this.state = {
            value: '',
            error: false            
        };
        
    }

    // Called before render. It should return an object to update the state or null to update nothing
    // Generally to re-compute some data only when a prop changes this is used
    static getDerivedStateFromProps(nextProps, prevState)
    {
        return { value: nextProps.value };
    }


    onChange = (evt,date_) => {

        const name = this.props.name;
        var value;
        if(date_ !== undefined){
            value = evt;
            console.log(name,value)
        }else{
            value = evt.target.value;
        }
        
        var error;
        if(this.props.isRequired && value === '')
        {
            error = name + ' is required';
        }
        else
        {
            error = this.props.validate ? this.props.validate(value) : false;
        }
        
        this.props.onChange({name, value, error});
    };

    renderLabel = () => {
        return (
            <label htmlFor={this.props.name}>{this.props.labelName}</label>
        );
    }

    render(){
        let label = this.props.labelName ? this.renderLabel(): '';
        return (
            <div className="form-group">
                {label}
                <InputField {...this.props} onChange={this.onChange} />
                <span style={{color: 'red'}}>{this.props.error}</span>
            </div>
        );
    }

};

export default Field;