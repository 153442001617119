import React from 'react';
import Field from '../components/Field';
import {contactForm, validateForm} from '../components/formFields';

import GuestService from '../services/GuestService';

class Contact  extends React.Component{
    constructor(props){
        super(props);
        var state = Object.assign({},{fields:{},fieldErrors:{},status:'',buttonTitle:''});
        Object.keys(contactForm).forEach(function(key){
            state.fields[key] = '';
        });
        this.state = state;
    }

    resetForm = () => {
        var stateObject = Object.assign({},this.state);
        Object.keys(contactForm).forEach(function(key){
            stateObject.fields[key] = '';
        });
        this.setState({fields:stateObject.fields});
    };

    getData =()=>{
        return {
            "data":{
                "type":"node--priyadarshini_contact_us",
                "attributes":{
                    "title":this.state.fields["name"],
                    "field_nursing_email":this.state.fields["email"],
                    "field_nursing_message":this.state.fields["message"],
                    "field_nursing_mobile":this.state.fields["mobile"]
                }
            }
        };
    }

    onFormSubmit = evt => {
        evt.preventDefault();
        var result = validateForm(this.state.fields,contactForm);
        if(result.error)
        {

            this.setState(Object.assign({},this.state,{fieldErrors: result.fieldErrors}));
        }
        else{
            this.setState({status:'Please Wait',buttonTitle:'Please Wait'},()=>{
                GuestService.fetchInfo(this.getData(),'contactUs')
                .then((response)=> {
                    var resObj =JSON.parse(response);
                    if(resObj.data !== undefined)
                    {
                        this.resetForm();
                        this.setState({status:'You have successfully sent message.We will get back to you soon',buttonTitle:''});
                    }else{
                        this.setState({status:resObj.errors[0]['title'],buttonTitle:''});
                    }
                }).catch(err =>{
                    this.setState({status:err,buttonTitle:''},()=>{

                    });
                })
            });
            
        }
    };

    onInputChange = ({name, value, error}) => {
        const fields = Object.assign({}, this.state.fields);
        const fieldErrors = Object.assign({}, this.state.fieldErrors);

        fields[name] = value;
        fieldErrors[name] = error;

        this.setState({fields, fieldErrors});
    };

    renderFields  = () => {
        var jsx = [];
        var i = 0;
        Object.keys(contactForm).forEach((key)=>{
            jsx[i++] = 
            
                <Field labelName={contactForm[key]['labelName']} 
                    // isRequired={contactForm[key]['isRequired']} 
                    placeHolder={contactForm[key]['placeholder']} 
                    type={contactForm[key]['type']}
                    name={key}
                    key={key}
                    validate={contactForm[key]['validate'] ? contactForm[key]['validate']:undefined}
                    onChange={this.onInputChange} 
                    value={this.state.fields[key]} 
                    error={this.state.fieldErrors[key] ? this.state.fieldErrors[key]: ''}
                />
            ;
        });
        return jsx;
    };

    render(){
        return (
            <div className="container" >

                <div className="row justify-content-center">

                <div className="col-md-12">

                    <div className="card o-hidden border-0 shadow-lg my-5">
                    <div className="card-body p-0">
                        <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <div className="p-5">
                            <div className="text-center">
                                <h3 className="h4 text-gray-900 mb-4">Write to Us</h3>
                            </div>
                            <form id="login-user" className="user" onSubmit={this.onFormSubmit} >
                                
                                {this.renderFields()}
                               
                                
                                <div onClick={this.state.buttonTitle.trim() === '' ? this.onFormSubmit: ()=>{}} 
                                    title={this.state.buttonTitle.trim() === '' ? 'Register':  this.state.buttonTitle}  
                                    className="btn btn-primary btn-user btn-block">
                                     Contact Us
                                </div>
                                <div id="register-form-status">{this.state.status}</div>
                            </form>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>

                </div>

                </div>

            </div>
        );
    }

}

export default Contact;