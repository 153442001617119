import  React  from 'react';
import Slider from './Slider';
import Aim from './Aim';

const Home = (props) => (
    <>
    <Slider gallery={props.gallery}/>
    <Aim />
    </>
);

export default Home;