import React from 'react';
import { Link } from 'react-router-dom';
import Environment from '../environment';

class ContactDetails  extends React.Component{
    constructor(props){
        super(props);
        let contactUs = ''
        if(props.about !== null){
            if(props.about.data && props.about["data"].length){
                for (let index = 0; index < props.about["data"].length; index++) {
                    const element = props.about["data"][index];
                    if(element.id === "f7bea818-3de0-4611-8150-d508e813aa16"){
                        contactUs = 
                            "<p>"+element.attributes.body.summary+"</p>"+element.attributes.body.processed
                        break;
                    }
                    
                }
            }
        }
        var state = Object.assign({},{contactUs:contactUs});
        this.state = state;
    }



    render(){
        return (
            <div className="container" >

                <div className="row justify-content-center">
                    <div className="col-md-12">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                    <div className="card-body p-0">
                        <div className="row">
                        <div className="col-md-10 offset-md-1">
                        <div className="p-5">
                            <div className="text-center">
                                <h3 className="h4 text-gray-900 mb-4">Contact Details</h3>
                            </div>
                            <p dangerouslySetInnerHTML={{ __html: this.state.contactUs }}></p>
                            <div className="text-center">
                            <Link to={Environment.homePage+'/contact-us'} className="btn btn-primary">Write to Us</Link>
                            </div>
                            
                            </div>
                            
                            </div>
                            </div>
                            </div>
                            </div>
                    </div>

                </div>

            </div>
        );
    }

}

export default ContactDetails;