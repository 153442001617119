import React from 'react';
import { Link } from 'react-router-dom';
import Environment from '../environment';

function Footer() {
    return (
      <div className="footer">
      <div className="container">
        
        <div className="row">
          <div className="col-12">
            <div className="copyright">
                <p>
                  <Link className="link-green" to={Environment.homePage+'/'}>Home</Link> 
                  | <Link className="link-green" to={Environment.homePage+'/about-us'}>About Us</Link>
                   | <Link className="link-green" to={Environment.homePage+'/gallery'}>Gallery</Link>
                    | <Link className="link-green" to={Environment.homePage+'/courses'}>Courses</Link>
                    | <Link className="link-green" to={Environment.homePage+'/contact-details'}>Contact Us</Link>
                </p>
                <p>
                    Powered By <a className="link-green" rel="noopener noreferrer" href="https://www.computerscan.in" target="_blank" >Computerscan Team</a>
                    </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
}
  
  export default Footer;