import Environment from '../environment';

const GuestService = (function() {

    function getRequestResource(resource){
        return Environment.endpoints[resource];
    }

    function fetchInfo(data,resource){
        return new Promise((resolve,reject)=>{
            var requestResource = getRequestResource(resource);
            var obj;
            var query='';
            if(requestResource.method === 'GET'){
                if(data !== null){
                    query = "?"+changeToFormBody(data);
                }
                obj = {};
            }
            else{
                // obj = {
                //     body: changeToFormBody(data)
                // };
                obj = {
                    body: JSON.stringify(data)
                }
            }
            var options = Object.assign({},{
                method: requestResource.method,
                mode: requestResource.cors ? 'cors': 'no-cors',
                headers:{
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            },obj);
            fetch(requestResource.url+query,options).then(response=>{
                // opaque is returned for cross site request
                if (response.type === 'opaque' || response.ok) {
                    return response.text();
                } else {
                // do something else
                }
            }).then(data=>{
                return resolve(data);
            }).catch(err=>{
               return reject(err);
            })
        });

    }

    function changeToFormBody(data)
    {
        var formBody = [];
        for (var property in data) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(data[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        return formBody;
    }

    return { fetchInfo }

})();

export default GuestService;