const hostname = window && window.location && window.location.hostname;
let endpoints = {};

let homePage = '';

if(hostname === 'localhost' || hostname === '127.0.0.1' || hostname === '192.168.1.4'){
    endpoints['aboutUs'] = { url: "http://127.0.0.1:8000/api/about_us/" , method: 'GET',cors:true};
    endpoints['contactUs'] = { url: "http://127.0.0.1:8000/api/contact_us/" , method: 'POST',cors:true};
    endpoints['application'] = { url: "http://127.0.0.1:8000/api/application/" , method: 'POST',cors:true};
    endpoints['gallery'] = { url: "http://127.0.0.1:8000/api/gallery/" , method: 'GET',cors:true};
}
else{
    
    endpoints['aboutUs'] = { url: "/api/about_us/" , method: 'GET',cors:true};
    endpoints['contactUs'] = { url: "/api/contact_us/" , method: 'POST',cors:true};
    endpoints['application'] = { url: "/api/application/" , method: 'POST',cors:true};
    endpoints['gallery'] = { url: "/api/gallery/" , method: 'GET',cors:true};
}

module.exports = {endpoints,hostname,homePage};