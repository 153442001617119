import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-image-lightbox/style.css";
import "./styles/style.css";
import "./styles/gallery.css";
import './App.css';

import Header from './components/Header';
import Home from './components/Home';
import Footer from './components/Footer';

import NoMatch from './components/NoMatch';
import {  Switch, Route, Link } from 'react-router-dom';
import Environment from './environment';
import GalleryImages from './components/Gallery';
import Contact from './components/Contact';
import About from './components/About';
import Application from './components/Application';
import GuestService from './services/GuestService';
import Infra from './components/Infra';
import Courses from './components/Courses';
import ContactDetails from './components/ContactDetails';

class  App extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      "gallery":null,
      "aboutus":null
    }
  }

  componentDidMount(){
    if(this.state.gallery === null){
      GuestService.fetchInfo({},"gallery").then(data=>{
        let jsonData = JSON.parse(data);
        this.setState({gallery:jsonData},()=>{

          GuestService.fetchInfo({},"aboutUs").then(data=>{
              let jsonData = JSON.parse(data);
              this.setState({aboutus:jsonData})
            })
        })
      }).catch(err=>{
        console.log(err)
      })
    }
  }


  render(){
    
    return (
      <div className="site-wrap">
          <div className="site-mobile-menu site-navbar-target">
              <div className="site-mobile-menu-header">
                  <div className="site-mobile-menu-close mt-3">
                  <span className="icon-close2 js-menu-toggle"></span>
                  </div>
              </div>
              <div className="site-mobile-menu-body"></div>
          </div>

          <div className="py-2 bg-light">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-9 d-none d-lg-block">
                    <Link to={Environment.homePage+'/contact-us'} className="link-green small mr-3"><span className="icon-question-circle-o mr-2"></span> Have a questions?</Link> 
                    <Link to="#" className="link-green small mr-3"><span className="icon-phone2 mr-2"></span> 9581998664</Link> 
                    <Link to="#" className="link-green small mr-3"><span className="icon-envelope-o mr-2"></span> tholathlily@yahoo.co.in</Link> 
                  </div>
                  <div className="col-lg-3 text-right">
                    <Link to={Environment.homePage+'/application'} className="small btn btn-primary px-4 py-2 rounded-0"> Apply</Link>
                  </div>
                </div>
              </div>
          </div>

          <Header />
          <Switch>
              <Route exact path={Environment.homePage+'/'} component={()=><Home gallery={this.state.gallery} />}  />
              <Route path={Environment.homePage+'/about-us'} component={()=><About  about={this.state.aboutus} />} />
              <Route path={Environment.homePage+'/infra'} component={()=><Infra about={this.state.aboutus} />} />
              <Route path={Environment.homePage+'/courses'} component={()=><Courses about={this.state.aboutus} />} />
              <Route path={Environment.homePage+'/contact-us'} component={()=><Contact  />} />
              <Route path={Environment.homePage+'/contact-details'} component={()=><ContactDetails about={this.state.aboutus} />} />
              <Route path={Environment.homePage+'/gallery'} component={()=><GalleryImages  gallery={this.state.gallery} />} />
              <Route path={Environment.homePage+'/application'} component={Application} />
              <Route component={NoMatch} />
          </Switch>
          <Footer />
      </div>
    );
}
}

export default App;
