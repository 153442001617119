import React from 'react';
import { Link } from 'react-router-dom';
import Environment from '../environment';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

function Header() {
    return (
        <header className="site-navbar js-sticky-header site-navbar-target" role="banner">

        <div className="container">

          <Navbar collapseOnSelect expand="lg" >
              <Link className="navbar-brand" to={Environment.homePage+'/'} >
                  <img src="/images/logo.png" alt="Logo" className="img-fluid" />
              </Link>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="mr-auto site-menu main-menu">
                      <Link className="nav-a text-left" to={Environment.homePage+'/'}>Home</Link>
                      <Link className="nav-a text-left" to={Environment.homePage+'/about-us'}>About Us</Link>
                      <Link className="nav-a text-left" to={Environment.homePage+'/infra'}>Infrastructure</Link>                      
                      <Link  to={Environment.homePage+'/gallery'} className="nav-a text-left">Gallery</Link>
                      <Link  to={Environment.homePage+'/courses'} className="nav-a text-left">Courses</Link>
                      <Link  to={Environment.homePage+'/contact-details'} className="nav-a text-left">Contact Us</Link>
                  </Nav>
              </Navbar.Collapse>
              <div className="mr-auto d-none d-xl-block">
              <div className="social-wrap">
                  <a  rel="noopener noreferrer" href="https://www.facebook.com/Priyadarshini-college-of-nursing-110137504104153/" target="_blank"><span className="icon-facebook"></span></a>
                </div>
              </div>
              
          </Navbar>

                
        </div>
  
      </header>
    );
}
  
  export default Header;