import  React,{useState,useEffect}  from 'react';

const Infra = (props) => {

    const [college,setCollege] = useState('');
    const [dataLoaded,setDataLoaded] = useState(false);

    useEffect(()=>{
        let isCancelled = false;
        if(dataLoaded === false){
            if(props.about !== null){
                if(props.about.data && props.about["data"].length){
                    for (let index = 0; index < props.about["data"].length; index++) {
                        const element = props.about["data"][index];
                        if(element.id === "e3039243-ab97-495e-97da-baf9b48a4232"){
                            if(isCancelled === false)
                            setCollege(
                                "<p>"+element.attributes.body.summary+"</p>"+element.attributes.body.processed
                            )
                            return
                        }
                        
                    }
                }
                setDataLoaded(true)
            }

        }

        return function cleanup() {
            isCancelled = true;
        };

    },[dataLoaded,props.about])
    if(props.about === null){
        
    }
    else{
        
    }

    return (
        <div >
        <div className="site-section">
            <div className="container">
                <div className="row mb-5 ">
                    <div className="col-md-12 mb-5">
                        <h2 className="section-title-underline text-center">
                        <span>Infrastructure</span>
                        </h2>
                        <p dangerouslySetInnerHTML={{ __html: college }}></p>
                    </div>
                    
                </div>
            </div>
        </div>
        </div>
    )
};

export default Infra;