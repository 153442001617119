import  React,{useState} from 'react';

import Lightbox from "react-image-lightbox";

const GalleryImages = (props) => {

    const [isOpen,setIsOpen] = useState(false);
    const [photoIndex,setPhotoIndex] = useState(0);

    function getRandomSize(min, max) {
        return Math.round(Math.random() * (max - min) + min);
    }

    const imageClick = (evt) => {
        setPhotoIndex(evt.target.dataset.id);
        setIsOpen(true);
    }

    let imagesPrefix = 'https://app.computerscan.in';

  if(props.gallery === null)
      return '';

    let imagesData = [];
    if(props.gallery["data"] !== undefined && props.gallery["data"].length){
      for (let index = 0; index < props.gallery["data"].length; index++) {
        const element = props.gallery["data"][index];
        if(element.id === "c2f90608-0e2e-408f-a664-665fc5665c3e"){
            imagesData = element.relationships.field_nursing_images.data;
        }
      }
    }

    let imagesInfo = imagesData.map(data=>{
      for (let index = 0; index < props.gallery["included"].length; index++) {
        const element = props.gallery["included"][index];
        if (element.id === data.id){
          return Object.assign({},data,{"url":imagesPrefix+element.attributes.uri.url})
        }
      }
      return {};
    })
    let imagesInfoData = imagesInfo.map(data=>{
        return {
            "src" : data.url,
            "caption": data.meta.title,
            "thumbnail": data.url,
            "thumbnailWidth" : parseInt(data.meta.width / 10),
            "thumbnailHeight" : parseInt(data.meta.height / 10)
        }
    })
    let images  = imagesInfo.map(data=>{
        return data.url
    })

    return (
    <div >
        <div className="site-section">
            <div className="container">
                <div className="row mb-5 justify-content-center text-center">
                    <div className="col-lg-4 mb-5">
                        <h2 className="section-title-underline mb-5">
                        <span>Gallery</span>
                        </h2>
                    </div>
                </div>
                <div id="photos">
                    {
                        imagesInfoData.map((data,index)=>{
                            var width = getRandomSize(200, 400);
                            var height =  getRandomSize(200, 400);
                            return (<img data-id={index} onClick={imageClick} key={index} src={data.src} width={width} height={height} alt={data.caption} />)
                            
                        })
                    }
                </div>
            </div>
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
    </div>
    )
    };

export default GalleryImages;